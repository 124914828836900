import React from "react";
import TrackingProvider from "../context/TrackingProvider";

const withTracking = (Component: React.ComponentType) => {
    return (props: object) => {
        return (
            <TrackingProvider>
                <Component {...props} />
            </TrackingProvider>
        );
    };
};

export default withTracking;